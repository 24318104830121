<template lang='pug'>
Home(
  :aprInitial='$store.state.settings.apr'
  :downPaymentInitial='$store.state.settings.downPayment'
  :downPaymentPercentInitial='$store.state.settings.downPaymentPercent'
  :priceInitial='$store.state.settings.price'
  :termInitial='$store.state.settings.term'
  @update:apr='apr => $store.commit("aprUpdate", apr)'
  @update:downPayment='downPayment => $store.commit("downPaymentUpdate", downPayment)'
  @update:downPaymentPercent='downPaymentPercent => $store.commit("downPaymentPercentUpdate", downPaymentPercent)'
  @update:price='price => $store.commit("priceUpdate", price)'
  @update:term='term => $store.commit("termUpdate", term)'
)
</template>

<script>
import Home from '../components/Home.vue';

export default
{
  components:
  {
    Home
  }
};
</script>