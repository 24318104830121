const apr = 3;
const downPaymentPercent = 20;
const price = 300 * 1000;
const downPayment = price * downPaymentPercent / 100;
const term = 30;

const state = () =>
({
  apr,
  downPayment,
  downPaymentPercent,
  price,
  term,
});

const mutation = key => (state, value) =>
{
  state[key] = value;
};

const aprUpdate = mutation('apr');
const downPaymentPercentUpdate = mutation('downPaymentPercent');
const downPaymentUpdate = mutation('downPayment');
const priceUpdate = mutation('price');
const termUpdate = mutation('term');

export default
{
  state,
  mutations:
  {
    aprUpdate,
    downPaymentPercentUpdate,
    downPaymentUpdate,
    priceUpdate,
    termUpdate,
  },
};