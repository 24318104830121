<template lang='pug'>
v-container
  v-text-field(
    label='Home price'
    :value='price'
    required
    type='number'
    @input='priceUpdate'
    prefix='$'
  )
  v-row
    v-col
      v-text-field(
        label='Down payment'
        :value='downPayment'
        required
        type='number'
        @input='downPaymentUpdate'
        prefix='$'
      )
    v-col
      v-text-field(
        label='Down payment'
        :value='downPaymentPercent'
        required
        type='number'
        @input='downPaymentPercentUpdate'
        prefix='%'
      )
  v-text-field(
    label='Mortgage amount'
    :value='mortgageAmount'
    readonly
    filled
    prefix='$'
  )
  v-text-field(
    label='Length of loan in years'
    :value='term'
    required
    type='number'
    @input='termUpdate'
  )
  v-text-field(
    label='Interest rate'
    :value='apr'
    required
    type='number'
    @input='aprUpdate'
    prefix='%'
  )
  v-text-field(
    label='Estimated monthly payment'
    :value='paymentRounded'
    readonly
    filled
    prefix='$'
  )
  //- div
    router-link(
      v-if='downPaymentPercentSanitized === 20 && termSanitized === 30'
      :to='{ name: "Link", params }'
    )
      pre Share your numbers with this link
    router-link(
      v-if='downPaymentPercentSanitized !== 20 && termSanitized === 30'
      :to='{ name: "LinkPriceAprDownPayment", params }'
    )
      pre Share your numbers with this link
    router-link(
      v-if='termSanitized !== 30'
      :to='{ name: "LinkPriceAprDownPaymentTerm", params }'
    )
      pre Share your numbers with this link
</template>

<script>
import { cond, defaultTo, divide, get, isFinite, multiply, pick, pipe, placeholder, round, tap } from 'lodash/fp';

import { stringToNumber } from '@thomasphan/vue-components/src/functions/stringToNumber';

const aprSanitized = function ()
{
  return pipe
  ([
    get('apr'),
    this.stringToNumber,
    defaultTo(0),
    divide(placeholder, 100),
  ])
  (this);
};

const downPaymentSanitized = function ()
{
  return pipe
  ([
    get('downPayment'),
    this.stringToNumber,
    defaultTo(0),
  ])
  (this);
};

const downPaymentPercentSanitized = function ()
{
  return pipe
  ([
    get('downPaymentPercent'),
    this.stringToNumber,
    defaultTo(0),
  ])
  (this);
};

const priceSanitized = function ()
{
  return pipe
  ([
    get('price'),
    this.stringToNumber,
    defaultTo(0),
  ])
  (this);
};

const termSanitized = function ()
{
  return pipe
  ([
    get('term'),
    this.stringToNumber,
    defaultTo(0),
  ])
  (this);
};

const aprUpdate = function (apr)
{
  this.apr = apr;

  this.$emit('update:apr', apr);
};

const downPaymentUpdate = function (downPayment)
{
  this.downPayment = downPayment;

  this.$emit('update:downPayment', downPayment);

  pipe
  ([
    get('downPaymentSanitized'),
    divide(placeholder, this.priceSanitized),
    multiply(100),
    tap(cond([[isFinite, downPaymentPercent => this.downPaymentPercent = downPaymentPercent]])),
    tap(cond([[isFinite, downPaymentPercent => this.$emit('update:downPaymentPercent', downPaymentPercent)]])),
  ])
  (this);
};

const downPaymentPercentUpdate = function (downPaymentPercent)
{
  this.downPaymentPercent = downPaymentPercent;

  this.$emit('update:downPaymentPercent', downPaymentPercent);

  pipe
  ([
    get('downPaymentPercentSanitized'),
    multiply(this.priceSanitized),
    divide(placeholder, 100),
    tap(cond([[isFinite, downPayment => this.downPayment = downPayment]])),
    tap(cond([[isFinite, downPayment => this.$emit('update:downPayment', downPayment)]])),
  ])
  (this);
};

const priceUpdate = function (price)
{
  this.price = price;

  this.$emit('update:price', price);

  this.downPaymentPercentUpdate(this.downPaymentPercent);
};

const termUpdate = function (term)
{
  this.term = term;

  this.$emit('update:term', term);
};

const aprMonthly = function ()
{
  return pipe
  ([
    get('aprSanitized'),
    divide(placeholder, 12),
  ])
  (this);
};

const mortgageAmount = function ()
{
  return this.priceSanitized - this.downPaymentSanitized;
};

const payment = function ()
{
  const pmt = p => i => n =>
  (
    p * i * window.Math.pow(1 + i, n) / (window.Math.pow(1 + i, n) - 1)
  );

  const result = pmt
  (this.mortgageAmount)
  (this.aprMonthly)
  (this.termMonths);

  return this.aprMonthly ? result : this.mortgageAmount / this.termMonths;
};

const paymentRounded = function ()
{
  return pipe
  ([
    get('payment'),
    multiply(100),
    round,
    divide(placeholder, 100),
  ])
  (this);
};

const termMonths = function ()
{
  return pipe
  ([
    get('termSanitized'),
    multiply(12),
  ])
  (this);
};

const aprInitial =
{
  required: true
};

const downPaymentInitial =
{
  required: true
};

const downPaymentPercentInitial =
{
  required: true
};

const priceInitial =
{
  required: true
};

const termInitial =
{
  required: true
};

const params = function ()
{
  return pick
  ([
    'price',
    'apr',
    'downPayment',
    'term',
  ])
  (this);
};

export default
{
  computed:
  {
    aprMonthly,
    aprSanitized,
    downPaymentSanitized,
    downPaymentPercentSanitized,
    mortgageAmount,
    params,
    payment,
    paymentRounded,
    priceSanitized,
    termSanitized,
    termMonths,
  },
  data()
  {
    return {
      apr: this.aprInitial,
      downPayment: this.downPaymentInitial,
      downPaymentPercent: this.downPaymentPercentInitial,
      price: this.priceInitial,
      term: this.termInitial,
    };
  },
  methods:
  {
    aprUpdate,
    downPaymentUpdate,
    downPaymentPercentUpdate,
    priceUpdate,
    stringToNumber,
    termUpdate,
  },
  props:
  {
    aprInitial,
    downPaymentInitial,
    downPaymentPercentInitial,
    priceInitial,
    termInitial,
  },
};
</script>